import gql from 'graphql-tag';
import {globalfunc} from '../../../../shared/GlobalFunction.js';
import moment from 'moment';

class MachineService {
    readItemTransferQuery(){
        var query = `
            query ( $paging: ServerPaging, $status:String) {
                GetStorageTrx (Paging: $paging, status: $status ) {
                    storage_trx_v {
                        trx_id
                        source_storage_id
                        source_storage_name
                        target_storage_id
                        target_storage_name
                        status
                        description
                        storage_trx_detail {
                            trx_id
                            item_id
                            item_data {
                                item_id
                                product_code
                                item_name
                                barcode
                                item_type
                                active_flag
                                type_detail_id
                                base_uom
                                base_weight
                                created_at
                                last_update
                            } 
                            base_uom
                            qty_request
                            qty_sent
                            qty_recieved
                            created_at
                            last_update
                        }
                        created_at
                        last_update
                    }
                    total
                }
            }
        `;
        return query;
    }

    async deleteQuery(variables){
        var query = gql`
            mutation ($id:Int!) {
                DeleteStorageTrx (Id:$id)
            }
        `;
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    async addQuery(variables){
        var query = gql`
            mutation ($data:NewStorageTrx!) {
                CreateStorageTrx (New:$data)
            }
        `;
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }
    
    async editQuery(variables){
        var query = gql`
            mutation ($id:Int!, $data:NewStorageTrx!) {
                UpdateStorageTrx (Id:$id, New:$data)
            }
        `;
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    async getStorageId(){
        var query = gql`
            query{
                GetStorage{
                    storage_id
                    storage_name
                }
            }
        `;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var storageData = [];
        for (let i = 0; i < result.data.GetStorage.length; i++) {
            var label = result.data.GetStorage[i].storage_id + ' (' + result.data.GetStorage[i].storage_name + ')'
            var str = {value:result.data.GetStorage[i].storage_id, label:label}
            storageData.push(str);
        }
        return storageData
    }

    readItemDataQuery(){
        var query = `
            query ($id: String) {
                GetItemStockV2(StorageId: $id){
                    item_stock {
                        storage_id
                        storage_name
                        item_id
                        product_code
                        item_name
                        barcode
                        item_type
                        base_uom
                        weight
                        stock
                        item_stock_detail {
                            storage_id
                            storage_name
                            item_id
                            product_code
                            item_name
                            barcode
                            item_type
                            base_uom
                            uom
                            weight
                            stock
                        }
                    }
                    total
                }
            }
        `;
        return query;
    }

    gridDataConverter(data){
        let array = [];
        for (let i = 0; i < data.length; i++) {
            var str = {
                item_id: data[i].item_id,
                product_code: data[i].product_code,
                item_name: data[i].item_name,
                base_uom: data[i].base_uom,
                stock: data[i].stock,
                qty_request: data[i].qty_request == undefined ? 0 : data[i].qty_request,
                qty_sent: data[i].qty_sent == undefined ? 0 : data[i].qty_sent,
                qty_recieved: data[i].qty_recieved == undefined ? 0 : data[i].qty_recieved,
            }
            array.push(str);
        }
        return array;
    }

    gridSelector(selected, checkall, data){
        if (checkall == 'true'){
            if(selected == ''){
                var newData = data;
                return newData;
            }else{
                var selectedData =  selected.split(",");
                var hashMap = [];
                data.forEach(el=>{
                    hashMap[el.item_id]= el
                })
                for (let i = 0; i < selectedData.length; i++) {
                    delete hashMap[selectedData[i].toString()]
                }
                var newData = [];
                Object.keys(hashMap).forEach(el=>{
                    newData.push(hashMap[el])
                })
                return newData;
            }
        }else{
            if(selected == ''){
                var newData = [];
                return newData;
            }else{
                var selectedData =  selected.split(",");
                var hashMap = [];
                data.forEach(el=>{
                    hashMap[el.item_id]= el
                })
                var newData = [];
                for (let i = 0; i < selectedData.length; i++) {
                    newData.push(hashMap[selectedData[i].toString()])
                }
                return newData;
            }
        }
    }

    async getItemTransferData(id){
        const variables = {
            id : id
        }
        var query = gql`
            query ( $id: Int) {
                GetStorageTrx (ID: $id) {
                    storage_trx_v {
                        trx_id
                        source_storage_id
                        source_storage_name
                        target_storage_id
                        target_storage_name
                        status
                        description
                        storage_trx_detail {
                            trx_id
                            item_id
                            item_data {
                                item_id
                                product_code
                                item_name
                                barcode
                                item_type
                                active_flag
                                type_detail_id
                                base_uom
                                base_weight
                                created_at
                                last_update
                            } 
                            base_uom
                            qty_request
                            qty_sent
                            qty_recieved
                            created_at
                            last_update
                        }
                        created_at
                        last_update
                    }
                    total
                }
            }
        `;
        var result = await globalfunc.defaultApolloQueryDefault(query, variables);
        return result.data.GetStorageTrx.storage_trx_v[0];
    }

    
    ////Excel
    balanceExcelHeaderSize() {
        var size = [
            {width: 30}, //colA
            {width: 20}, //colB
            {width: 20}, //colC
            {width: 20}, //colD
            // {width: 20}, //colE
            // {width: 20}, //colF
        ];

        return size;
    }

    balanceExcel(data) {
        var arrayObject = [];
        var date = 'Tanggal ' + moment(new Date()).format('DD MMM YYYY');
        var numberFormat = '_(* #,##0_);_(* (#,##0);_(* "-"_);_(@_)';

        var startIndex = 6;
        var lastIndex = startIndex;

        if(data != null){
            lastIndex = lastIndex + data.length - 1;
        }

        //Header
        var row1 = {colA : 'PT BOJONG WESTPLAS',};
        arrayObject.push(row1);

        var row2 = {colA : 'ITEM TRANSFER',};
        arrayObject.push(row2);

        var row3 = {colA : date};
        arrayObject.push(row3);
        
        var spaceRow = {colA : null};
        arrayObject.push(spaceRow);
        
        var row5 = {colA : 'Sumber : ' + data.source_storage_name};
        arrayObject.push(row5);
        
        var row6 = {colA : 'Target : ' + data.target_storage_name};
        arrayObject.push(row6);
        
        var row7 = {colA : 'Keterangan : ' + data.description};
        arrayObject.push(row7);
        
        var spaceRow2 = {colA : null};
        arrayObject.push(spaceRow2);
        
        var row9 = {
            colA : 'Kode Item',
            colB : 'Nama Item',
            colC : 'Satuan',
            colD : 'Qty Request',
            // colE : 'Qty Sent',
            // colF : 'Qty Received'
        };
        arrayObject.push(row9);

        if(data != null){
            for (let i = 0; i < data.storage_trx_detail.length; i++){
                var row = {
                    colA : data.storage_trx_detail[i].item_data.product_code,
                    colB : data.storage_trx_detail[i].item_data.item_name,
                    colC : data.storage_trx_detail[i].item_data.base_uom,
                    colD : data.storage_trx_detail[i].qty_request == null ? 0 : { v: data.storage_trx_detail[i].qty_request, t: 'n', z: numberFormat },
                    // colE : data.storage_trx_detail[i].qty_sent == null ? 0 : { v: data.storage_trx_detail[i].qty_sent, t: 'n', z: numberFormat },
                    // colF : data.storage_trx_detail[i].qty_recieved == null ? 0 : { v: data.storage_trx_detail[i].qty_recieved, t: 'n', z: numberFormat },
                };
                arrayObject.push(row);
            }
        }

        return arrayObject;
    }
}


export default new MachineService();