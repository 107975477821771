import gql from 'graphql-tag';
import {globalfunc} from '../../../../shared/GlobalFunction.js';

class MinimalStockItemService {
    readMinimalStockItemQuery(){
        var query = `
            query ($paging: ServerPaging, $id: String) {
                GetItemStockMinimalV (Paging: $paging, StorageId: $id) {
                    item_stock_minimal{
                        created_at
                        last_update
                        storage_id
                        item_id
                        base_stock
                        minimal_stock
                        color
                        item_name
                        product_code
                        item_type
                        type_detail_id
                        type_detail_name
                    }
                    total
                }
            }
        `;
        return query;
    }

    async getStorageId(){
        var query = gql`
            query{
                GetStorage{
                    storage_id
                    storage_name
                }
            }
        `;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var data = result.data.GetStorage;
        var storageData = [];

        if(data != null){
            for (let i = 0; i < data.length; i++) {
                var str = { value: data[i].storage_id, 
                            label: data[i].storage_id + ' (' + data[i].storage_name + ')'}
                storageData.push(str);
            }
        }
        return storageData
    }

    async editQuery(variables){
        const query = gql`mutation($data:[NewItemStockMinimal!]!){
            UpdateItemStockMinimal(New:$data)
        }`
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }
}

export default new MinimalStockItemService();