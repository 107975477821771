<template>
    <div>
        <input hidden type="text" class="form-control" id="TrxId" v-model="TrxId">
        <div class="modal fade" id="ItemTransferModal" tabindex="-1" aria-labelledby="ItemTransferModalLabel" aria-hidden="true" style="overflow:auto;">
            <div class="modal-dialog modal-xl modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 id="ItemTransferModalLabel" class="font-weight-bold">{{ModalTitle}}{{this.SourceStorageId == null ? "": this.SourceStorageId.label}}</h4>
                        <a type="button" data-bs-dismiss="modal" aria-label="Close">
                            <i class="fa fa-times"></i>
                        </a>
                    </div>

                    <div class="modal-body">
                        <div class="p-3">
                            <CRow>
                                <CCol sm="3">
                                    <label class="form-label font-weight-bold required">Sumber Storage</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="4">
                                    <v-select id="SourceStorageId" class="pb-3" :options="SourceStorageIdData" v-model="SourceStorageId" :disabled="disabledSourceStorageValue" />
                                </CCol>                                    
                            </CRow>
                            <CRow>
                                <CCol sm="3">
                                    <label class="form-label font-weight-bold required">Target Storage</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="4">
                                    <v-select id="TargetStorageId" class="pb-3" :options="TargetStorageIdData" v-model="TargetStorageId" :disabled="disabledTargetStorageValue" />
                                </CCol>                                    
                            </CRow>
                            <CRow>
                                <CCol sm="3">
                                    <label class="form-label font-weight-bold">Status</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="4">
                                    <CSelect id="Status" class="font-weight-bold" :options="StatusData" :value.sync="Status" />
                                    <label id="errorStatus" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>
                            <CRow>
                                <CCol sm="3">
                                    <label class="form-label font-weight-bold">Deskripsi</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="4">
                                    <textarea class="form-control" rows="2" v-model="Description"></textarea>
                                </CCol>                                    
                            </CRow>
                            <hr>

                            <div class="py-2">
                                <button class="btn btn-primary"
                                    @click="addFormClick()">
                                    <i class="fa fa-plus"></i> Add
                                </button>
                            </div>

                            <datasource ref="itemDataSource" :data="this.GridData" :page-size="10" :schema-model-fields="this.GridDataSchemaModel"/>

                            <kendo-grid ref="gridItem"
                                :data-source-ref="'itemDataSource'"
                                :columns="this.ItemTransferDetailColumn"
                                :editable="this.editTable"
                                :pageable="true"
                                :resizable="true"
                                :filterable="true"
                                :sortable="true"
                                :cellClose="cellClose">
                            </kendo-grid>

                        </div>
                        <br>

                        <label id="errorItemGeneral" class="form-error" style="display: none; color: red;"></label>                        
                        <CButton type="button" @click="saveClick()" class="save-button" id="saveButton" color="success" style="float: right;"> Save </CButton>
                        <label style="float: right;">&nbsp;&nbsp;</label>
                        <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close"> Close </CButton>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { globalfunc } from '../../../../shared/GlobalFunction.js';
import { Grid, GridColumn} from '@progress/kendo-grid-vue-wrapper';
import { DataSource } from '@progress/kendo-datasource-vue-wrapper';
import ItemTransferServices from '../Script/ItemTransferServices.js';
import { response } from '../../../../infrastructure/constant/response';

export default {
    name: 'ItemTransferForm',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
        'datasource' : DataSource,
    },
    props: ['reload', 'addItemClick'],
    data: function () {
        return {
            ModalTitle: '',
            TrxId: '',

            SourceStorageId: '',
            SourceStorageIdData: [],
            disabledSourceStorageValue: '',

            TargetStorageId: '',
            TargetStorageIdData: [],
            disabledTargetStorageValue: '',

            Status: '',
            StatusData: [],
            Description: '',
            
            ItemTransferDetailColumn: [],
            GridId: '',
            GridData: [],
            GridDataSchemaModel: {
                item_id: { type: "string", editable: false},
                product_code: { type: "string", editable: false},
                item_name: { type: "string", editable: false},
                base_uom: {type: "string", editable:false},
                stock: {type: "number", editable:false},
                qty_request: { type: "number", editable: true, validation: { min: 0, required: true} },
                qty_sent: { type: "number", editable: true, validation: { min: 0, required: true} },
                qty_recieved: { type: "number", editable: true, validation: { min: 0, required: true} },
            },
            editTable: true,
            
            SaveType: '',
            Error: 0,
            gridDetailReload: 0,
        }
    },
    methods: {
        async addClick(){    
            $(".form-error").css("display", "none");        
            this.ModalTitle = 'Add Item Transfer: ';

            var StorageData = await ItemTransferServices.getStorageId()
            this.SourceStorageIdData = StorageData;
            this.SourceStorageId = '';
            this.disabledSourceStorageValue = false;

            this.TargetStorageIdData = StorageData;
            this.TargetStorageId = '';
            this.disabledTargetStorageValue = false;

            this.StatusData = [ {value: 'New', label: 'New'} ];
            this.Status = 'New';

            this.Description = '';

            this.editTable = true;
            this.GridData = [];
            this.ItemTransferDetailColumn =  [
                { field: 'product_code', title: "Kode Item", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: 'item_name', title: "Nama Item", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: 'base_uom', title: "UOM", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: 'stock', title: "Stock Item", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" } },
                { field: 'qty_request', title: "Qty Request", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" } },
                { field: 'qty_sent', editable: this.addEditable, title: "Qty Sent", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" } },
                { field: 'qty_recieved', editable: this.addEditable, title: "Qty Received", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" } },
                { command: ['destroy'], title:'&nbsp;', width: 100, attributes: { "class": "k-text-center" } }
            ];
            
            this.SaveType = 'Add';
            
            window.$('#ItemTransferModal').modal('show');
        },
        async editClick(itemTransferData, View){
            $(".form-error").css("display", "none");
            $(".save-button").show();
            this.ModalTitle = 'Edit Item Transfer: ';

            this.TrxId = itemTransferData.trx_id;

            var StorageData = await ItemTransferServices.getStorageId();
            this.SourceStorageIdData = StorageData;
            this.SourceStorageId = this.SourceStorageIdData.find(c => c.value == itemTransferData.source_storage_id);
            this.disabledSourceStorageValue = true;

            this.TargetStorageIdData = StorageData;
            this.TargetStorageId = this.TargetStorageIdData.find(c => c.value == itemTransferData.target_storage_id);
            this.disabledTargetStorageValue = true;

            this.StatusData = await globalfunc.globalDropdown('ddl_transaction_storage');
            this.Status = itemTransferData.status;
    
            this.Description = itemTransferData.description;
            
            this.editTable = true;
            this.GridData = globalfunc.objectToArrayConverter(itemTransferData.storage_trx_detail, "ItemTransfer-DetailGrid");
            this.ItemTransferDetailColumn =  [
                { field: 'product_code', title: "Kode Item", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: 'item_name', title: "Nama Item", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: 'base_uom', title: "UOM", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: 'qty_request', editable: this.newEditable, title: "Qty Request", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" } },
                { field: 'qty_sent', editable: this.sentEditable, title: "Qty Sent", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" } },
                { field: 'qty_recieved', editable: this.receiveEditable, title: "Qty Received", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" } },
            ];

            this.SaveType = 'Edit';
            this.reloadDetail();
            
            if(View){
                $(".save-button").hide();
                this.ModalTitle = 'View Item Transfer: ';
            }

            window.$('#ItemTransferModal').modal('show');
        },        
        addEditable() {
            return false
        },
        newEditable() {
            var edit = false;
            if (this.Status == "New") {
                edit = true
            }
            return edit
        },
        sentEditable() {
            var edit = false;
            if (this.Status == "Approved" || this.Status == "Sent") {
                edit = true
            }
            return edit
        },
        receiveEditable() {
            var edit = false;
            if (this.Status == "Sent") {
                edit = true
            }
            return edit
        },
        cellClose(e){
            var grid = this.$refs.gridItem.kendoWidget();
            var dataItem = e.sender.dataItem($(e.container).parent());
            
            if(this.Status == "Approved" && dataItem.qty_sent < dataItem.qty_request){
                this.$swal("Warning", "Quantity Sent lebih kecil dari Quantity Request", "warning");
            }
            if(this.Status == "Sent" && dataItem.qty_sent < dataItem.qty_request){
                this.$swal("Warning", "Quantity Sent lebih kecil dari Quantity Request", "warning");
            }
            if(this.Status == "Sent" && dataItem.qty_recieved < dataItem.qty_sent){
                this.$swal("Warning", "Quantity Received lebih kecil dari Quantity Sent", "warning");
            }
            if(this.Status == "Recieved" && dataItem.qty_recieved < dataItem.qty_sent){
                this.$swal("Warning", "Quantity Sent lebih kecil dari Quantity Request", "warning");
            }

            if(dataItem.qty_sent > dataItem.qty_request){
                this.$swal("Error", "Quantity Sent tidak boleh lebih besar dari Quantity Request", "error");
                dataItem.qty_sent = dataItem.qty_request;
            }
            if(dataItem.qty_recieved > dataItem.qty_sent){
                this.$swal("Error", "Quantity Received tidak boleh lebih besar dari Quantity Sent", "error");
                dataItem.qty_recieved = dataItem.qty_sent;
            }
        },
        async inputValidation() {
            this.Error = 0;
            $(".form-error").css("display", "none");
            var ItemTransferData = this.$refs.gridItem.kendoWidget().dataSource._data;

            if ( this.TargetStorageId.value === undefined ){
                this.Error++;
                this.$swal("Error", "Target Storage harus di isi", "error");
            }
            
            if ( ItemTransferData.length == 0){
                this.Error++;
                this.$swal("Error", "Data Item harus di isi", "error");
            }

            for (let i = 0; i < ItemTransferData.length; i++) {
                if ( ItemTransferData[i].qty_request > ItemTransferData[i].stock ){
                    this.Error++;
                    this.$swal("Error", "Jumlah Qty Request tidak boleh lebih besar dari jumlah stock yang ada", "error");
                }
            }
        },
        async saveClick() {
            this.inputValidation();

            if(this.Error == 0) {
                if(this.SaveType == 'Add') {
                    this.$loading(true);
                    var ItemTransferData = this.$refs.gridItem.kendoWidget().dataSource._data;

                    var ItemTransferDetailArray = [];
                    for (let i = 0; i < ItemTransferData.length; i++) {
                        var detail = { 
                            item_id: ItemTransferData[i].item_id,
                            base_uom: ItemTransferData[i].base_uom,
                            qty_request: ItemTransferData[i].qty_request,
                            qty_sent: ItemTransferData[i].qty_sent,
                            qty_recieved: ItemTransferData[i].qty_recieved
                        }
                        ItemTransferDetailArray.push(detail)
                    }

                    const InputItemTransferData = {
                        source_storage_id : this.SourceStorageId.value,
                        target_storage_id : this.TargetStorageId.value,
                        status : this.Status,
                        description : this.Description,
                        new_storage_trx_detail : ItemTransferDetailArray
                    }

                    const variables = {
                        data : InputItemTransferData
                    }
                    
                    ItemTransferServices.addQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successSave, "success");
                        window.$('#ItemTransferModal').modal('hide');
                        this.$props.reload();
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = globalfunc.ErrorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
                else if(this.SaveType == 'Edit'){
                    this.$loading(true);
                    var ItemTransferData = this.$refs.gridItem.kendoWidget().dataSource._data;

                    var ItemTransferDetailArray = [];
                    for (let i = 0; i < ItemTransferData.length; i++) {
                        var detail = { 
                            item_id: ItemTransferData[i].item_id,
                            base_uom: ItemTransferData[i].base_uom,
                            qty_request: ItemTransferData[i].qty_request,
                            qty_sent: ItemTransferData[i].qty_sent,
                            qty_recieved: ItemTransferData[i].qty_recieved
                        }
                        ItemTransferDetailArray.push(detail)
                    }

                    const InputItemTransferData = {
                        source_storage_id : this.SourceStorageId.value,
                        target_storage_id : this.TargetStorageId.value,
                        status : this.Status,
                        description : this.Description,
                        new_storage_trx_detail : ItemTransferDetailArray
                    }

                    const variables = {
                        id : parseInt(this.TrxId),
                        data : InputItemTransferData
                    }
                    
                    ItemTransferServices.editQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successUpdate, "success");
                        window.$('#ItemTransferModal').modal('hide');
                        this.$props.reload();
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = globalfunc.ErrorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })  
                }
            }
        },
        reloadDetail(){
            this.gridDetailReload++;
        },
        addFormClick(){
            if (this.SourceStorageId.value === undefined ){
                this.$swal("Error", "Sumber Storage harus di isi", "error");
            }
            else {
                this.$props.addItemClick(this.SourceStorageId.value);
            }
        },
        ItemTransferDetailGridData(data){
            var gridData = this.$refs.gridItem.kendoWidget().dataSource._data;
            var ItemTransferItemData = ItemTransferServices.gridDataConverter(gridData);

            var inputData = ItemTransferServices.gridDataConverter(data);
            
            ItemTransferItemData = ItemTransferItemData.concat(inputData);

            this.GridData  = Object.values(ItemTransferItemData.reduce((a, c) => (a[`${c.product_code}${c.item_name}`] = c, a), {})); 
        },    
    }
}
</script>
