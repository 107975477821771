<template>
    <div>
        <div class="modal fade" id="MinimalStockItemModal" tabindex="-1" aria-labelledby="MinimalStockItemModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-md modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 id="MinimalStockItemModalLabel" class="font-weight-bold">{{ModalTitle}}</h4>
                        <a type="button" data-bs-dismiss="modal" aria-label="Close">
                            <i class="fa fa-times"></i>
                        </a>
                    </div>

                    <div class="modal-body">
                        <div class="p-3">
                            <CRow>
                                <CCol sm="4">
                                    <label class="form-label font-weight-bold">Kode Gudang</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="8">
                                    <CInput id="StorageId" v-model="StorageId" class="font-weight-bold" readonly/>
                                    <label id="errorStorageId" class="form-error" style="display: none; color: red;"></label>
                                </CCol>   
                                <CCol sm="4">
                                    <label class="form-label font-weight-bold">Kode Item</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="8">
                                    <CInput id="ItemCode" v-model="ItemCode" class="font-weight-bold" readonly/>
                                    <label id="errorItemCode" class="form-error" style="display: none; color: red;"></label>
                                </CCol>
                                <CCol sm="4">
                                    <label class="form-label font-weight-bold">Nama Item</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="8">
                                    <CInput id="ItemName" v-model="ItemName" class="font-weight-bold" readonly/>
                                    <label id="errorItemName" class="form-error" style="display: none; color: red;"></label>
                                </CCol>
                                <CCol sm="4">
                                    <label class="form-label font-weight-bold">Tipe Item</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="8">
                                    <CInput id="ItemType" v-model="ItemType" class="font-weight-bold" readonly/>
                                    <label id="errorItemType" class="form-error" style="display: none; color: red;"></label>
                                </CCol>
                                <CCol sm="4">
                                    <label class="form-label font-weight-bold">Base Stock</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="8">
                                    <CInput type="number" id="BaseStock" v-model="BaseStock" class="font-weight-bold" readonly/>
                                    <label id="errorBaseStock" class="form-error" style="display: none; color: red;"></label>
                                </CCol> 
                                <CCol sm="4">
                                    <label class="form-label font-weight-bold">Minimal Stock</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="8">
                                    <CInput type="number" id="MinimalStock" v-model="MinimalStock" class="font-weight-bold"/>
                                    <label id="errorMinimalStock" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                                 
                            </CRow>
                        </div>
                        <CButton type="button" @click="saveClick()" class="save-button" id="saveButton" color="success" style="float: right;"> Save </CButton>
                        <label style="float: right;">&nbsp;&nbsp;</label>
                        <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close"> Close </CButton>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import { response } from '../../../../infrastructure/constant/response';
import minimalStockItemServices from '../Script/MinimalStockItemServices.js';

export default {
    name: 'MinimalStockItemForm',
    components: {
    },
    props: ['reload'],
    data: function () {
        return {
            ModalTitle : '',
            StorageId : null,
            ItemId : null,
            ItemName : null,
            ItemCode : null,
            ItemType : null,
            BaseStock : null,
            MinimalStock : null,
            Error: 0,
            SaveType: '',
        }
    },
    methods: {
        async editClick(data, view){
            this.ModalTitle = 'Edit Minimal Stock: ';
            this.StorageId = data.storage_id;
            this.ItemId = data.item_id;
            this.ItemName = data.item_name;
            this.ItemCode = data.product_code;
            this.ItemType = data.item_type;
            this.BaseStock = data.base_stock;
            this.MinimalStock = data.minimal_stock;

            this.SaveType = 'Edit';

            if(view){
                $(".save-button").hide();
            }

            window.$('#MinimalStockItemModal').modal('show');
        },
        async inputValidation() {
            this.Error = 0;
            $(".form-error").css("display", "none");

            if(this.MinimalStock == '' || this.MinimalStock == null){
                this.errorShow('errorMinimalStock');
            }
        },
        errorShow(errorCode){
            document.getElementById(errorCode).style.display = 'block';
            document.getElementById(errorCode).innerHTML = response.fieldRequired;
            if(this.Error <= this.$globalvariable.max_error_focus){ document.getElementById(errorCode.replace("error", "")).focus(); }
            this.Error++;
        },
        async saveClick(){
            this.inputValidation();

            if(this.Error == 0){
                const minimalStockData = [{
                    storage_id: this.StorageId,
                    item_id: this.ItemId,
                    minimal_stock: parseFloat(this.MinimalStock)
                }];

                if (this.SaveType == 'Edit'){
                    this.$loading(true);

                    const variables = {
                        data : minimalStockData
                    }
                    
                    minimalStockItemServices.editQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successUpdate, "success");
                        window.$('#MinimalStockItemModal').modal('hide');
                        this.$props.reload();
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = this.$globalfunc.ErrorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            }
        },
    }
}
</script>

<style scoped>
.modal-xl-custom {
    max-width: 1500px;
}
</style>