<template>
    <div>
        <CCard class="w-100">
            <CCardHeader>
                <h2>Item Transfer</h2>
            </CCardHeader>
            <CCardBody>
                <span v-html="statusButton"></span>

                <div class="col-xs-6 text-light pb-2">
                    <button id="add" class="btn btn-success"
                        @click="addClick()">
                        <i class="fa fa-plus"></i> Add
                    </button>
                </div>

                <itemTransfer-grid ref="grid" :key="gridReload" :deleteClick="deleteClick" :editClick="editClick" :statusClick="statusClick" :excelClick="excelClick"/>
                <itemTransfer-form ref="ItemTransferForm" :addItemClick="addItemClick" :reload="reload"/>
                <item-form ref="itemDataForm" :saveItemTransferItemGrid="saveItemTransferItemGrid"/>
                <itemTransfer-status-form ref="ItemTransferStatusForm" :reload="reload"/>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import { globalfunc } from '../../../shared/GlobalFunction';
import '@progress/kendo-ui';
import '@progress/kendo-theme-default/dist/all.css';
import moment from 'moment'
import ItemTransferServices from '../ItemTransfer/Script/ItemTransferServices';
import ItemTransferGrid from '../ItemTransfer/Grid/ItemTransferGrid.vue';
import ItemTransferForm from '../ItemTransfer/Component/ItemTransferForm.vue'
import ItemDataForm from '../ItemTransfer/Component/ItemDataForm.vue';
import ItemTransferStatusForm from '../ItemTransfer/Component/ItemTransferStatusForm.vue';
import { response } from '../../../infrastructure/constant/response';
import { sweet_alert } from '../../../infrastructure/constant/variable';

export default {
    name: 'ItemTransfer',
    components: {
        'itemTransfer-grid': ItemTransferGrid,
        'itemTransfer-form': ItemTransferForm,
        'item-form' : ItemDataForm,
        'itemTransfer-status-form': ItemTransferStatusForm,
    },
    async mounted () {
        var permission = this.$globalfunc.permissionModule('Item Transfer');
        document.getElementById("add").disabled = permission.cperms ? false : true;
        
        this.statusFilter();
    },
    data(){
        return{
            gridReload: 0,
            statusButton: '',
        }
    },
    methods: {
        addClick(){
            this.$refs.ItemTransferForm.addClick();
        },
        editClick(itemTransferData, view){
            this.$refs.ItemTransferForm.editClick(itemTransferData, view);
        },
        addItemClick(data){
            this.$refs.itemDataForm.addClick(data);
        },
        saveItemTransferItemGrid(data){
            this.$refs.ItemTransferForm.ItemTransferDetailGridData(data);
        },
        deleteClick(id){
            this.$swal(sweet_alert.delete).then((result) => {
                var variables = {
                    id : id
                }
                if (result.isConfirmed == true) {                    
                    ItemTransferServices.deleteQuery(variables).then(res =>{
                        this.reload();
                        this.$swal("Info", response.successDelete, "success");
                    })
                    .catch(e => {
                        var errorMessage = this.$globalfunc.ErrorExtractor(e)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            })
        },
        statusClick(data) {
            this.$refs.ItemTransferStatusForm.statusClick(data);
        },
        async excelClick(dataItem) {
            // const variables = {
            //     id : data.id,
            //     startDate: this.DateFrom,
            //     endDate: this.DateTo
            // }
            var gridData = await ItemTransferServices.getItemTransferData(dataItem.id);
            var data = ItemTransferServices.balanceExcel(gridData);
            
            var XLSX = require("xlsx");
            var dateNow = moment(new Date()).format("YYYY-MM-DD-HHmmss");

            var filename = 'ItemTransfer_' + dateNow + '.xlsx';
            var ws = XLSX.utils.json_to_sheet(data, {skipHeader: true});
            ws['!cols'] = ItemTransferServices.balanceExcelHeaderSize();
            
            var wb = XLSX.utils.book_new();

            XLSX.utils.book_append_sheet(wb, ws);
            XLSX.writeFile(wb, filename);
        },
        reload(){
            this.gridReload++;
            this.statusFilter();
        },
        async statusFilter() {
            // var countStatus = await globalfunc.countTotal(window.location.pathname);
            // this.statusButton = await this.$globalfunc.dynamicStatusButton('ddl_transaction_storage', countStatus);
            this.statusButton = await this.$globalfunc.dynamicStatusButton();
            
            var vue = this;
            this.$nextTick(function () {
                $("#btnAll").on('click', function() {
                    vue.reload();
                });
                $("#btnSent").on('click', function() {
                    vue.$refs.grid.changeStatus('Sent');
                });
                $("#btnRecieved").on('click', function() {
                    vue.$refs.grid.changeStatus('Recieved');
                });
                $("#btnApproved").on('click', function() {
                    vue.$refs.grid.changeStatus('Approved');
                });
                $("#btnReject").on('click', function() {
                    vue.$refs.grid.changeStatus('Reject');
                });
                $("#btnNew").on('click', function() {
                    vue.$refs.grid.changeStatus('New');
                });
            })
        },
    }
}
</script>

<style scoped>
</style>