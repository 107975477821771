<template>
  <div>
        <kendo-grid
            ref="grid" 
            :data-source="minimalStock"
            :filterable="filterableConfig"
            :sortable="true"
            :pageable="pageableConfig"
            :columns="columns"
            :resizable="true"
            v-on:databound="ColorMeBad"
        >
        </kendo-grid>
  </div>
</template>


<script>
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import {globalfunc} from '../../../../shared/GlobalFunction';
import minimalStockItemServices from '../Script/MinimalStockItemServices.js';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection';
import { kendo_grid } from '../../../../infrastructure/constant/variable';

export default {
    name: 'MinimalStockItemGrid',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
    },
    props: ['StorageId', 'editClick'],
    computed: {
        minimalStock () {
            var storageId = this.StorageId;
            return new kendo.data.DataSource({
                page: 1,
                pageSize: kendo_grid.default_grid_pagesize,
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true,
                transport: {
                    read: {
                        contentType: "application/json",
                        url: apiBaseUrl,
                        type: "POST",
                        data: function(e) {
                            const paging = {
                                Page : {Limit: e.pageSize,Page: e.page},
                                Filter : globalfunc.filterQueryBuilder(e.filter),
                                OrderBy : globalfunc.sortingQueryBuilder(e.sort)
                            }
                            return { 
                                query: minimalStockItemServices.readMinimalStockItemQuery(),
                                    variables : {
                                        id : storageId, 
                                        paging : paging
                                    }
                            };
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                            req.setRequestHeader('Path', window.location.pathname);
                        }
                    },
                    parameterMap: function(options, operation) {
                        return  kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    cache: false,
                },
                schema: {
                    data: function (response) {
                        if (response.data.GetItemStockMinimalV.item_stock_minimal == null) {
                            return [];
                        }
                        else {
                            return response.data.GetItemStockMinimalV.item_stock_minimal;
                        }
                    },
                    total: function (response) {
                        if (response.data.GetItemStockMinimalV.item_stock_minimal == null) {
                            return 0;
                        }
                        else {
                            return response.data.GetItemStockMinimalV.total;
                        }
                    },
                }
            })
        }
    },
    data: function () {
        return  {
            columns:  [
                { title: "Action", width: 150, attributes: { style: "text-align:center;" }, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, template:this.columnButton},
                { field: "storage_id", title: "Kode Gudang", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }},
                { field: "product_code", title: "Kode Item", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }},
                { field: "item_name", title: "Nama Item", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }},
                { field: "type_detail_name", title: "Tipe Detail", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }},
                { field: "base_stock", title: "Stock", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:right;"} },
                { field: "minimal_stock", title: "Minimal Stock", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:center;"} }
            ],
            filterableConfig:  {
                extra: true,
                operators: kendo_grid.default_operator
            },
            pageableConfig: kendo_grid.default_pageable_config
        };
    },
    mounted: async function() {
        var grid = this.$refs.grid.kendoWidget();
        var editClick = this.$props.editClick;

        var GridElement = grid.element;

        GridElement.on("click", "#EditButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));
            
            editClick(dataItem, false);
        })

        GridElement.on("click", "#ViewButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            editClick(dataItem, true);
        })
    },
    methods: {
        columnButton(){
            return this.$globalfunc.gridActionButton("Minimal Stock Item")
        },
        ColorMeBad() {
            var grid = this.$refs.grid.kendoWidget();
            var data = grid.dataSource.data();
            $.each(data, function (i, row) {
                $('tr[data-uid="' + row.uid + '"] ').css("background-color", row.color);
            })
        }
    },
}
</script>