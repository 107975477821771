<template>
  <div>
        <kendo-grid
            ref="grid" 
            :data-source="dataSource"
            :filterable="filterableConfig"
            :sortable="true"
            :pageable="pageableConfig"
            :columns="columns"
            v-on:detailinit="detailInit"
            :resizable="true"
        >
        </kendo-grid>
  </div>
</template>


<script>
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import {globalfunc} from '../../../../shared/GlobalFunction';
import ItemTransferServices from '../Script/ItemTransferServices.js';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection'
import { kendo_grid } from '../../../../infrastructure/constant/variable';

export default {
    name: 'ItemTransferGrid',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
    },
    props: ['editClick', 'deleteClick', 'statusClick', 'excelClick'],
    data: function () {
        return  {
            dataSource: new kendo.data.DataSource({
                page: 1,
                pageSize: kendo_grid.default_grid_pagesize,
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true,
                transport: {
                    read: {
                        contentType: "application/json",
                        url: apiBaseUrl,
                        type: "POST",
                        data: function(e) {
                            const paging = {
                                Page : {Limit: e.pageSize,Page: e.page},
                                Filter : globalfunc.filterQueryBuilder(e.filter),
                                OrderBy : globalfunc.sortingQueryBuilder(e.sort)
                            }
                            const variables = {
                                paging : paging
                            }
                            return { 
                                query: ItemTransferServices.readItemTransferQuery(),
                                variables : variables
                            };
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                        }
                    },
                    parameterMap: function(options, operation) {
                        return  kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    cache: false,
                },
                schema: {
                    data: function (response) {
                        if(response.data.GetStorageTrx.storage_trx_v == null){
                            return [];
                        }else{
                            return response.data.GetStorageTrx.storage_trx_v;
                        }
                    },
                    total: function (response) {
                        if(response.data.GetStorageTrx.total == null){
                            return 0;
                        }else{
                            return response.data.GetStorageTrx.total;
                        }
                    },
                    model: {
                        fields: {
                            created_at: { type: "date" }
                        }
                    }
                },
                sort: { field: "created_at", dir: "desc" }
            }),
            columns:  [
                { title: "Action", width: 200, attributes: { style: "text-align:center;" }, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, template:this.columnButton},
                { field: "status", title: "Status", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:center;"} },
                { field: "source_storage_id", title: "Sumber Kode Storage", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "source_storage_name", title: "Sumber Nama Storage ", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "target_storage_id", title: "Target Kode Storage", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "target_storage_name", title: "Target Nama Storage", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "description", title: "Deskripsi", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "created_at", title: "Tanggal Dibuat", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:center;"}, 
                    template: "#= kendo.toString(kendo.parseDate(new Date(created_at).toISOString(), 'yyyy-MM-dd'), 'yyyy-MM-dd') #"
                }
            ],
            filterableConfig:  {
                extra: true,
                operators: kendo_grid.default_operator
            },
            pageableConfig: kendo_grid.default_pageable_config
        };
    },
    mounted: async function() {
        var grid = this.$refs.grid.kendoWidget();
        var editClick = this.$props.editClick;
        var deleteClick = this.$props.deleteClick;
        var statusClick = this.$props.statusClick;
        var excelClick = this.$props.excelClick;
       
        var GridElement = grid.element;

        GridElement.on("click", "#EditButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            editClick(dataItem, false);
        })

        GridElement.on("click", "#ViewButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            editClick(dataItem, true);
        })
        
        GridElement.on("click", "#DeleteButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));
           
            deleteClick(dataItem.trx_id);
        })
        
        ////Status Form
        GridElement.on("click", "#StatusButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            const data = {
                id : dataItem.trx_id,
                status : dataItem.status
            }
            
            statusClick(data);
        })

        
        GridElement.on("click", "#ExcelButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            const data = {
                id : dataItem.trx_id
            }
            
            excelClick(data);
        })
    },
    methods: {
        columnButton(){
            var customButton = `
                &nbsp;&nbsp;
                <button type="button" class="btn btn-secondary btn-sm rounded" id="ExcelButton">
                    <i class="fa fa-file-excel-o"></i> </span>
                </button> &nbsp;&nbsp;
            `;
            return this.$globalfunc.gridActionButton("Item Transfer", customButton)
        },
        detailInit: function (e) {
            $('<div/>').appendTo(e.detailCell).kendoGrid({
                ref: "ItemTransferDetailGrid",
                dataSource: {
                    transport: {
                        read: function (x) {
                            x.success(e.data.storage_trx_detail);
                        },
                    },
                    parameterMap: function(options, operation) {
                        return kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    schema: {
                        data: function (response) {
                            if(response == null)
                                return []
                            else
                                return response
                        },
                        total: function(response) {
                            if(response == null)
                                return 0
                            else
                                return response.length;
                        }
                    },
                    pageSize: kendo_grid.default_detail_grid_pagesize
                },
                scrollable: false,
                sortable: true,
                pageable: true,
                navigatable: true,
                resizable: true,
                columns: [
                    { field: 'item_data.product_code', title: "Kode Item", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, editable: false, nullable: true },
                    { field: 'item_data.item_name', title: "Nama Item", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, editable: false, nullable: true },
                    { field: 'base_uom', title: "UOM Dasar", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, editable: false, nullable: true },
                    { field: 'qty_request', title: "Quantity yang diminta", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" }, editable: false, nullable: true },
                    { field: 'qty_sent', title: "Quantity yang dikirim", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" }, editable: false, nullable: true },
                    { field: 'qty_recieved', title: "Quantity yang diterima", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" }, editable: false, nullable: true },
                ],
            })  
        },
        changeStatus(status){
            this.dataSource = new kendo.data.DataSource({
                page: 1,
                pageSize: kendo_grid.default_grid_pagesize,
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true,
                transport: {
                    read: {
                        contentType: "application/json",
                        url: apiBaseUrl,
                        type: "POST",
                        data: function(e) {
                            const paging = {
                                Page : {Limit: e.pageSize,Page: e.page},
                                Filter : globalfunc.filterQueryBuilder(e.filter),
                                OrderBy : globalfunc.sortingQueryBuilder(e.sort)
                            }
                            const variables = {
                                paging : paging,
                                status : status
                            }
                            return { 
                                query: ItemTransferServices.readItemTransferQuery(),
                                variables : variables
                            };
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                        }
                    },
                    parameterMap: function(options, operation) {
                        return  kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    cache: false,
                },
                schema: {
                    data: function (response) {
                        if(response.data.GetStorageTrx.storage_trx_v == null){
                            return [];
                        }else{
                            return response.data.GetStorageTrx.storage_trx_v;
                        }
                    },
                    total: function (response) {
                        if(response.data.GetStorageTrx.total == null){
                            return 0;
                        }else{
                            return response.data.GetStorageTrx.total;
                        }
                    },
                    model: {
                        fields: {
                            created_at: { type: "date" }
                        }
                    }
                },
                sort: { field: "created_at", dir: "desc" }
            });
        },
    },
    
}
</script>